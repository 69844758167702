@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "El Messiri", sans-serif; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  /* direction: rtl; */
  background-color: var(--body-bg-color);
}

div:where(.swal2-container) div:where(.swal2-timer-progress-bar) {
  background-color: var(--text-header) !important;
}
.ag-center-cols-container .my-shaded-effect {
  /* background-color: greenyellow !important; */
  /* opacity: 0; */
}
.ag-center-cols-container .shaded-effect {
  background-color: red !important;
  opacity: 0;
}
.bg_color {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  min-height: 100%;
  z-index: 10;
}
.Page .title {
  /* color: var(--p-color); */
  font-size: 30px;
  /* margin-bottom: 20px; */
}
/* Start Header Text */
.Header_text {
  text-align: center;
  padding: 0px 0 15px;
  font-size: 35px;
  font-weight: 600;
  color: var(--p-color);
}
.Header_text span {
  color: var(--text-header);
}

/*  start btn_loader */
.btn_loader .loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.btn_loader {
  background-color: var(--hover-Admin);
  padding: 16px 45px;
  border-radius: 20px;
}
.btn_loader .loader {
  width: 15px;
  aspect-ratio: 1;
  position: relative;
}
.btn_loader .loader::before,
.btn_loader .loader::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: #000;
}
.btn_loader .loader::before {
  box-shadow: -25px 0;
  animation: l8-1 1s infinite linear;
}
.btn_loader .loader::after {
  transform: rotate(0deg) translateX(25px);
  animation: l8-2 1s infinite linear;
}

@keyframes l8-1 {
  100% {
    transform: translateX(25px);
  }
}
@keyframes l8-2 {
  100% {
    transform: rotate(-180deg) translateX(25px);
  }
}
/* End btn_loader */
/* End   Header Text */
.bg_loader {
  background: #00000069;
  position: absolute;
  z-index: 12;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg_loader .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.bg_loader .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid var(--btn-send);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* start ag grid style*/
.ag-theme-alpine {
  --ag-foreground-color: var(--text-header);
  --ag-background-color: var(--main-bgcolor-admin) !important;
  --ag-selected-row-background-color: var(--p-color) !important;
  --ag-header-foreground-color: var(--p-color) !important;
  --ag-header-background-color: var(--main-sideBar-admin) !important;
  --ag-icon-size: 20px !important;
  --ag-font-size: 14px !important;
  /* --ag-list-item-height: 30px !important; */
  --ag-icon-font-color: var(--btn-send) !important;
}
.ag-root-wrapper {
  border: transparent !important;
}
.ag-header-row {
  direction: rtl;
}
.ag-root-wrapper.ag-layout-normal {
  /* direction: ltr; */
}
.ag-header-cell-sortable .ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label .ag-header-cell-text {
  font-size: 18px;
  font-weight: normal;
  direction: ltr;
}
.ag-cell {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: "100%" !important;
}
.ag-cell-value,
.ag-group-value {
  /* direction: rtl !important; */
}
.ag-cell-wrapper {
  width: 100% !important;
  /* height: 100%; */
}
.ag-header-cell-filtered {
  /* background-color: red !important; */
  /* color: red !important; */
}
.ag-theme-quartz .ag-header-cell {
  font-size: 24px !important;
}
.ag-header-cell-filtered span {
  color: var(--btn-send) !important;
}
.ag-row-odd {
  background-color: var(--nth-child) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
.ag-row {
  border-bottom: none !important;
  text-align: center;
  height: 60px !important;
}
.ag-header-cell-filtered span {
  color: var(--btn-send) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
/* end ag grid style*/

.p-paginator-element:focus,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--main-sideBar-admin) !important;
  color: var(--btn-send) !important;
}

@media (max-width: 767px) {
  .imageMedia {
    width: 70% !important;
    margin-top: -50px !important;
  }
}
