:root {
  --main-background: #212121;
  --main-bgcolor-admin: #c6c6c6;
  /* --main-sideBar-admin: #212121; */
  --main-sideBar-admin: #231f20;
  --tow-color: #5f2b14;
  --hover-Admin: #e7bb67;
  --bg-btn: #e7bb67;
  /* --nth-child: #999999; */
  --nth-child: #ededf1;
  /* --text-header: #235b95; */
  --text-header: #e7bb67;
  --input-ysr: #e7bb67;
  --btn-send: #e7bb67;
  --p-color: #fff;
}
